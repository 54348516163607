import { Link, Outlet, useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Box, Button, Stack, Alert } from "@mui/material";
import ApiContext from "../context/ApiContext";
import { AccessLevel } from "../interfaces/IGetUserResult";
import { NewtCloudLogo } from "../img/NewtCloudLogo";

export default function AppLayout() {
  const navigate = useNavigate();
  const {
    actions: { logout, isLoggedIn },
  } = useContext(AuthContext);
  const {
    data: { accessLevel },
  } = useContext(ApiContext);

  const handleBilling = async (e: any) => {
    e.preventDefault();
    navigate("/billing");
  };

  const handleSignout = async (e: any) => {
    e.preventDefault();
    logout();
    navigate("/signin");
  };

  return (
    <div className="customBody customFamily">
      <nav
        className="sticky top-0 border-b transition duration-200 z-50 ease-in-out animate-header-slide-down-fade border-transparent backdrop-blur-sm px-2 py-4 lg:p-4"
        data-headlessui-state=""
        id="__FPSC_ID_1_1710833276946"
      >
        <Alert icon={false} sx={{ marginBottom: 2 }} severity="info">
          Welcome to Nucleus! This website is currently in pre-launch mode.
          Stay tuned for exciting updates and the full launch soon!
        </Alert>
        <div className="mx-auto max-w-7xl">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <a target="_parent" href="/">
              <Box sx={{height: '75px'}}>
                <NewtCloudLogo />
              </Box>
            </a>

            {!isLoggedIn() && (
              <Link to="/signin">
                <Button>
                  <u>Sign in</u>
                </Button>
              </Link>
            )}

            {isLoggedIn() && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                {isLoggedIn() &&
                  accessLevel === AccessLevel.FREE &&
                  process.env.REACT_APP_STRIPE_ENABLE === "true" && (
                    <Button onClick={(e) => handleBilling(e)}>
                      <u>Upgrade to Pro</u>
                    </Button>
                  )}
                {isLoggedIn() &&
                  accessLevel === AccessLevel.PRO &&
                  process.env.REACT_APP_STRIPE_ENABLE === "true" && (
                    <Button onClick={(e) => handleBilling(e)}>
                      <u>Billing</u>
                    </Button>
                  )}
                {isLoggedIn() && (
                  <Button onClick={(e) => handleSignout(e)}>
                    <u>Sign Out</u>
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
        </div>
      </nav>
      <main>
        <div className="relative overflow-hidden isolate">
          <div className="relative px-2 lg:px-4">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
