import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HideIcon } from "../../img/HideIcon";
import { ShowIcon } from "../../img/ShowIcon";

interface IFormInput {
  email: string;
  password: string;
}

export default function RegisterEmailSection() {
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext<IFormInput>();

  return (
    <>
      <Typography variant="h4">Email</Typography>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={errors.email !== undefined}
            helperText={errors.email ? errors.email?.message : "　"}
            className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
            type="text"
            inputProps={{
              style: {
                padding: "10px 12px",
              },
            }}
          />
        )}
      />
      <Typography variant="h4">Password</Typography>
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={errors.password !== undefined}
            helperText={errors.password ? errors.password?.message : "　"}
            className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
            type={passwordShown ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setPasswordShown(!passwordShown)}
                  >
                    {!passwordShown ? <ShowIcon /> : <HideIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: {
                padding: "10px 12px",
              },
            }}
          />
        )}
      />
    </>
  );
}
