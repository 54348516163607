import { Alert, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ApiContext from "../../context/ApiContext";
import { AccessLevel } from "../../interfaces/IGetUserResult";
import { GoodbyeSection } from "./GoodbyeSection";
import { WelcomeSection } from "./WelcomeSection";
import { useMediaMobile } from "../../utils/utils";

interface GreetingSectionProps {
  setIsRegOpen: (open: boolean) => void;
}

export function GreetingSection(props: GreetingSectionProps) {
  const navigate = useNavigate();
  const {
    data: { accessLevel },
  } = useContext(ApiContext);
  const isMobile = useMediaMobile();
  const { setIsRegOpen } = props;
  const isPaidDisabled =
    (accessLevel !== AccessLevel.PRO &&
      process.env.REACT_APP_STRIPE_ENABLE === "true") ||
    (accessLevel === AccessLevel.GUEST &&
      process.env.REACT_APP_STRIPE_ENABLE !== "true");
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      spacing={0.5}
    >
      <Grid xs={12}>
        {accessLevel !== AccessLevel.PRO &&
          process.env.REACT_APP_STRIPE_ENABLE === "true" && (
            <Alert
              onClick={() => navigate("/store")}
              severity="warning"
              sx={{ cursor: "pointer", marginTop: 2, marginBottom: 2 }}
            >
              This feature is limited to Pro accounts. <u>Click here</u> to
              unlock.
            </Alert>
          )}
        {accessLevel === AccessLevel.GUEST &&
          process.env.REACT_APP_STRIPE_ENABLE !== "true" && (
            <Alert
              onClick={() => {
                if (setIsRegOpen) setIsRegOpen(true);
              }}
              severity="warning"
              sx={{ cursor: "pointer", marginTop: 2, marginBottom: 2 }}
            >
              This feature is limited. <u>Click here</u> to unlock.
            </Alert>
          )}
      </Grid>
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Welcome
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <WelcomeSection disabled={isPaidDisabled} />
      </Grid>
      <Grid item xs={12} md={2} lg={1.2}>
        <Typography variant="body1" sx={{ marginBottom: isMobile ? 0 : 1 }}>
          Goodbye
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={10}>
        <GoodbyeSection disabled={isPaidDisabled} />
      </Grid>
    </Grid>
  );
}
