import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useContext, useEffect, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import * as yup from "yup";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { Close } from "../img/Close";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { IAgentCreate } from "../interfaces/Agent";
import { ICityResult } from "../interfaces/ICityResult";
import {
  showFriendlyErrorFromData,
  showFriendlyErrorFromHTMLCode,
  titleCase,
  useMediaMobile,
  useReferer,
} from "../utils/utils";
import { default_location, default_voice } from "../utils/consts";

function formatCity(city: ICityResult): string {
  return city.city + ", " + city.region;
}
function unformatCity(cities: ICityResult[], location: string) {
  const parts = location.split(",", -1);
  const city = parts[0].trim();
  const region = parts[1].trim();
  return cities.find((c) => c.city === city && c.region === region);
}

interface IFormInput {
  cityId: ICityResult;
  businessName: string;
  voice: string;
  agents?: IAgentCreate[];
}
const locationSchema = {
  city: yup.string().required(),
  region: yup.string().required().length(2),
  country: yup.string().required().length(2),
};

const agentSchema = {
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .test((phone, ctx) => {
      if (!isValidPhoneNumber(phone, "CA"))
        return ctx.createError({
          message: `${phone} is not a valid phone number`,
        });
      return true;
    }),
};

const schema = yup
  .object({
    cityId: yup.object(locationSchema),
    businessName: yup.string().required("Business name field is required."),
    voice: yup.string().required("Voice field is required."),
    agents: yup
      .array()
      .min(1, "Oops. Please add at least one user for your system.")
      .of(yup.object().shape(agentSchema)),
  })
  .required();

export default function LandingViewForm() {
  const referer = useReferer();

  const {
    actions: { createGuest },
  } = useContext(AuthContext);
  const {
    data: { cities: defaultCities, voices },
    actions: { setLastWarning },
  } = useContext(ApiContext);

  const theme = useTheme();
  const isMobile = useMediaMobile();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [inputValue, setInputValue] = useState<string>(
    formatCity(default_location())
  );
  const [cities, setCities] = useState<ICityResult[]>(defaultCities);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cityId: cities && cities.length > 0 ? cities[0] : default_location(),
      businessName: "",
      voice:
        voices && voices.length > 0
          ? voices[0].name.toLowerCase()
          : default_voice(),
      agents: [{ first_name: "", last_name: "", phone: "" }],
    },
  });

  useEffect(() => {
    setCities(defaultCities);
  }, [defaultCities]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const cityId = getValues("cityId");
      if (!cityId) {
        const i = cities.findIndex(
          (item) => item.city === default_location().city
        );
        setValue("cityId", i >= 0 ? default_location() : cities[0]);
        setInputValue(formatCity(getValues("cityId")));
      }
    }
  }, [cities, getValues, setValue]);

  useEffect(() => {
    if (voices && voices.length) {
      const voice = getValues("voice");
      if (!voice) setValue("voice", voices[0].name.toLowerCase());
    }
  }, [voices, getValues, setValue]);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleAddAgent = () => {
    append({ first_name: "", last_name: "", phone: "" });
  };

  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { cityId, businessName, voice, agents } = data;
    if (!agents || agents?.length <= 0) return;

    setError("");
    setSuccess("");
    setLoading(true);
    createGuest(cityId, businessName, voice, agents, referer)
      .then((data) => {
        setError("");
        const msg = showFriendlyErrorFromData(data, "");
        if (msg) setLastWarning(msg);
      })
      .catch((error) => {
        setError(showFriendlyErrorFromHTMLCode(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper elevation={1}>
      <form
        className="p-10 space-y-5 w-[100%]"
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success} </Alert>}
        <>
          <div className="spasrc/views/LandingView.tsxe-y-2">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 sm:col-span-6 space-y-2">
                <Typography variant="h4">Your number</Typography>
                <div className="flex items-center justify-center w-full gap-2">
                  <Controller
                    name="cityId"
                    control={control}
                    render={({ field }) => {
                      const e = errors.cityId?.message;
                      return (
                        <FormControl
                          sx={{ width: "100%" }}
                          error={e !== undefined}
                        >
                          <Autocomplete
                            autoHighlight
                            value={formatCity(field.value)}
                            onChange={(event: any, newValue: string | null) => {
                              field.onChange(
                                newValue
                                  ? unformatCity(cities, newValue)
                                  : default_location()
                              );
                            }}
                            blurOnSelect
                            disableClearable
                            autoComplete
                            loading={loading}
                            options={cities
                              .filter((value) =>
                                inputValue.length <= 0
                                  ? value.is_preferred
                                  : true
                              )
                              .map((item) => formatCity(item))}
                            inputValue={inputValue}
                            onInputChange={(
                              e: React.SyntheticEvent,
                              newInputValue
                            ) => {
                              setInputValue(newInputValue);
                            }}
                            onFocus={() => {
                              setCities(defaultCities);
                              setInputValue("");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Start typing your city"
                                InputProps={{
                                  ...params.InputProps,
                                  sx: { boxShadow: "none", padding: "1px 3px" },
                                }}
                              />
                            )}
                            sx={{ width: "100%" }}
                          />
                          <FormHelperText>
                            {e ? e.toString() : "　"}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6 space-y-2">
                <Typography variant="h4">Receptionist Voice</Typography>
                <div className="flex items-center justify-center w-full gap-2">
                  <Controller
                    name="voice"
                    control={control}
                    render={({ field }) => {
                      const e = errors.voice?.message;
                      return (
                        <FormControl
                          sx={{ width: "100%" }}
                          error={e !== undefined}
                        >
                          <Select
                            {...field}
                            defaultValue={
                              voices && voices.length > 0
                                ? voices[0].name.toLowerCase()
                                : default_voice()
                            }
                          >
                            {voices
                              ?.filter((v) => v.is_premium === false)
                              .map((v) => {
                                return (
                                  <MenuItem
                                    key={v.name.toLowerCase()}
                                    value={v.name.toLowerCase()}
                                  >
                                    {titleCase(v.name)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {e ? e.toString() : "　"}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <Typography variant="h4">1. Your business name</Typography>
            <Controller
              name="businessName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.businessName !== undefined}
                  helperText={
                    errors.businessName
                      ? errors.businessName?.message?.toString()
                      : "　"
                  }
                  className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                  type="text"
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="space-y-2">
            <Typography variant="h4">2. List your team</Typography>
            <>
              {fields.map((agent, index) => (
                <Grid
                  key={agent.id}
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name={`agents.${index}.first_name`}
                      render={({ field }) => {
                        const e =
                          errors?.agents?.at !== undefined &&
                          errors?.agents.at(index) !== undefined &&
                          errors?.agents?.at(index)?.first_name?.message;
                        console.log(e);
                        return (
                          <TextField
                            {...field}
                            error={e !== undefined && e !== false}
                            helperText={e ? e.toString() : "　"}
                            sx={{ width: "100%" }}
                            type="text"
                            placeholder="First name"
                            inputProps={{
                              maxLength: 50,
                              style: {
                                padding: "10px 12px",
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name={`agents.${index}.last_name`}
                      render={({ field }) => {
                        const e =
                          errors?.agents?.at !== undefined &&
                          errors?.agents.at(index) !== undefined &&
                          errors?.agents?.at(index)?.last_name?.message;
                        return (
                          <TextField
                            {...field}
                            error={e !== undefined && e !== false}
                            helperText={e ? e.toString() : "　"}
                            sx={{ width: "100%" }}
                            type="text"
                            placeholder="Last name"
                            inputProps={{
                              maxLength: 50,
                              style: {
                                padding: "10px 12px",
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={fields.length > 1 ? 3 : 4}>
                    <Controller
                      control={control}
                      name={`agents.${index}.phone`}
                      render={({ field }) => {
                        const e =
                          errors?.agents?.at !== undefined &&
                          errors?.agents.at(index) !== undefined &&
                          errors?.agents?.at(index)?.phone?.message;
                        return (
                          <TextField
                            {...field}
                            error={e !== undefined && e !== false}
                            helperText={e ? e.toString() : "　"}
                            sx={{ width: "100%" }}
                            type="text"
                            placeholder="Phone"
                            inputProps={{
                              maxLength: 50,
                              style: {
                                padding: "10px 12px",
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {fields.length > 1 && (
                    <Grid item xs={12} md={1}>
                      {!isMobile ? (
                        <IconButton
                          disabled={fields.length <= 1}
                          onClick={() => handleRemoveAgent(index)}
                        >
                          <Close />
                        </IconButton>
                      ) : (
                        <Button
                          sx={{
                            width: "100%",
                            color: theme.palette.error.dark,
                          }}
                          variant="outlined"
                          onClick={() => handleRemoveAgent(index)}
                        >
                          Remove team member
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
              ))}
              <div>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  onClick={handleAddAgent}
                >
                  Add team member
                </Button>
              </div>
            </>
          </div>
        </>

        <Button variant="contained" sx={{ width: "100%" }} type="submit">
          Create my AI Phone System
          {loading ? (
            <LoadingCircle />
          ) : (
            <div className="flex items-center justify-center w-3 ml-1">
              <RightArrow />
            </div>
          )}
        </Button>
      </form>
    </Paper>
  );
}
