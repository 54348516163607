import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";

export interface ModelCardProps extends CardProps {
  index: number;
  disabled?: boolean;
  onCardRemove: () => void;
}

export function ModelCard(props: ModelCardProps) {
  const { index, disabled, onCardRemove, ...restProps } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  return (
    <Card
      {...restProps}
      sx={{
        backgroundColor: "#F8FAFE",
        ...props.sx,
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#7c7d7f", marginBottom: '16px' }} aria-label="recipe">
            {index + 1}
          </Avatar>
        }
        title={
          <Controller
            control={control}
            name={`cards.${index}.title`}
            render={({ field }) => {
              const e =
                errors?.cards?.at !== undefined &&
                errors?.cards.at(index) !== undefined &&
                errors?.cards?.at(index)?.title?.message;
              return (
                <TextField
                  {...field}
                  error={e !== undefined && e !== false}
                  helperText={e ? e.toString() : "　"}
                  sx={{
                    "& .MuiOutlinedInput-input": { background: "white" },
                    width: "100%",
                  }}
                  variant="outlined"
                  type="text"
                  placeholder="Title"
                  inputProps={{
                    maxLength: 50,
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                  disabled={disabled}
                  data-no-dnd="true"
                />
              );
            }}
          />
        }
      />
      <CardContent sx={{paddingTop: 0, paddingBottom: 0}}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
        >
          <Controller
            control={control}
            name={`cards.${index}.body`}
            render={({ field }) => {
              const e =
                errors?.cards?.at !== undefined &&
                errors?.cards.at(index) !== undefined &&
                errors?.cards?.at(index)?.body?.message;
              return (
                <TextField
                  {...field}
                  error={e !== undefined && e !== false}
                  helperText={e ? e.toString() : "　"}
                  sx={{ "& .MuiOutlinedInput-root": { background: "white" } }}
                  placeholder="Enter instructions for the system here"
                  multiline
                  rows={10}
                  disabled={disabled}
                  data-no-dnd="true"
                  inputProps={{
                    style: {
                      padding: "10px 12px",
                    },
                  }}
                />
              );
            }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => onCardRemove()}
          data-no-dnd="true"
          disabled={disabled}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}
