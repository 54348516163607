import { Theme, createTheme } from "@mui/material";

export const innerTheme = (outerTheme: Theme): Theme =>
  createTheme({
    palette: {
      ...outerTheme.palette,
    },
    typography: {
      allVariants: {
        color: outerTheme.palette.text.primary,
        fontStyle: "normal",
      },
      fontFamily: "Roboto",

      // H1
      h1: {
        fontSize: "54px",
        fontWeight: 500,
        lineHeight: 1.5,
      },
      // H2
      h2: {
        fontSize: 32,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      // H3
      h3: {
        fontSize: "22px",
        fontWeight: 800,
        lineHeight: 1.5,
      },
      // text bold
      h4: {
        fontSize: "17px",
        lineHeight: 1.5,
        fontWeight: 600,
      },
      h5: {
        fontSize: "16px",
        fontWeight: 800,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: "15px",
        lineHeight: 1.5,
      },
      // text
      body1: {
        fontSize: "14px",
        lineHeight: 1.5,
      },
      // data
      body2: {
        fontSize: "18px",
        lineHeight: 1.5,
      },

      // tips
      caption: {
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1,
      },
    },

    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.palette.divider}`,
            "&:not(:last-child)": {
              borderBottom: 0,
            },
            "&::before": {
              display: "none",
            },
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: theme.spacing(1),
            },
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({ textTransform: "inherit" }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF",
            boxShadow: "none",
            transition: "none",
            border: "1px solid #efefef",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: { textTransform: "inherit" },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingLeft: 2,
            paddingRight: 2,
            [theme.breakpoints.up("md")]: {
              paddingLeft: 16,
              paddingRight: 16,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            backgroundColor: "#E7FEF2",
            lineHeight: "20px",
          },
          standardError: {
            lineHeight: "20px",
          },
          standardWarning: {
            lineHeight: "20px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-input": {
              padding: "10px 12px",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: "1px 3px",
            },
          },
        },
      },
    },
  });

export const theme = createTheme({
  palette: {
    primary: {
      // blue
      main: "#5E6CB2",
      // light blue
      light: "#F2F6FF",
    },
    secondary: {
      // TODO replace if need, this is only for fix
      main: "#9c27b0",
      // Blue on dark
      dark: "#6F7DFF",
    },
    background: {
      default: "#FFF",
      paper: "#F8FAFE",
    },
    success: {
      // Green
      main: "#19B665",
      // Green light
      light: "#E8F8F0",
    },
    error: {
      // red
      main: "#E3153A",
      // red on dark
      dark: "#F6637F",
    },
    text: {
      // gray 1
      primary: "#333",
      secondary: "#FFF",
    },
    grey: {
      // black
      900: "#000",
      // Header bg color
      800: "#292A2F",
      // video
      700: "#36373E",
      // gray 5
      600: "#6C6C77",
      // gray 4
      500: "#A1A1A9",
      // gray 3
      400: "#BDBCC5",
      // gray 2
      300: "#E2E2E8",
      // gray 1
      200: "#EBEBF0",
      // light gray
      100: "#FAFAFC",
    },
  },
});
