import { TeamMember } from "./Agent";
import { CardItem } from "./CardItem";

export enum AccessLevel {
  SUSPENDED = "SUSPENDED",
  GUEST = "GUEST",
  FREE = "FREE",
  PRO = "PRO",
}

interface IAccount {
  id: number;
  username: string;
  access_level: AccessLevel;
}

interface ICompany {
  id: number;
  name: string;
  did: string;
  voice: string;
  users: TeamMember[];
  cards: CardItem[];
  welcome_message?: string;
  goodbye_message?: string;
  time_zone?: string;
}

export interface IGetUserResult {
  account: IAccount;
  company: ICompany;
}
