import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DaysOfWeek } from "../interfaces/Agent";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { TimezonePicker } from "./TimezonePicker";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface AvailabilityAccordianProps {
  index: number;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
}

export default function AvailabilityAccordian(
  props: AvailabilityAccordianProps
) {
  const { index, expanded, onChange } = props;
  const theme = useTheme();
  const titleCase = (s: string) => {
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  };

  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();

  const getError = (day: DaysOfWeek, fieldName: string) => {
    if (
      errors &&
      errors["agents"] &&
      errors["agents"][index] &&
      errors["agents"][index] !== undefined
    ) {
      const agentE: any = errors["agents"][index];
      return (
        agentE["availability"] &&
        agentE["availability"][day] &&
        (agentE["availability"][day] as any)[fieldName] &&
        (agentE["availability"][day] as any)[fieldName]["message"]
      );
    }
  };

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true } }}
      disableGutters
      elevation={0}
      square
      expanded={expanded === true}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography>Schedule</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          {Object.values(DaysOfWeek).map((day) => (
            <Controller
              control={control}
              name={`agents.${index}.availability.${day}.enabled`}
              render={({ field: enabledField }) => {
                const fieldValue =
                  enabledField.value === undefined || enabledField.value;
                return (
                  <Grid
                    key={day}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                    columnSpacing={2}
                  >
                    <Grid item xs={12} md={3}>
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          paddingBottom: 2,
                        }}
                      >
                        <Checkbox
                          sx={{
                            color: "#DEDEDE",
                          }}
                          checked={fieldValue}
                          onChange={(_, checked) =>
                            enabledField.onChange(checked)
                          }
                        />

                        <Typography
                          sx={{
                            lineHeight: "42px",
                            color: !fieldValue ? "#DEDEDE" : undefined,
                          }}
                        >
                          {titleCase(day)}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={9}>
                      <Grid
                        key={day}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: "100%" }}
                        columnSpacing={2}
                      >
                        <Grid item xs={12} md={6}>
                          <Controller
                            control={control}
                            name={`agents.${index}.availability.${day}.start`}
                            render={({ field }) => {
                              const e = getError(day, "start");

                              return (
                                <FormControl
                                  sx={{ width: "100%" }}
                                  error={e !== undefined}
                                >
                                  <TimezonePicker
                                    value={field.value}
                                    label="From"
                                    onChange={(v) => field.onChange(v)}
                                    showTz={false}
                                    disabled={!fieldValue}
                                  />
                                  <FormHelperText>
                                    {e ? e.toString() : "　"}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Controller
                            control={control}
                            name={`agents.${index}.availability.${day}.end`}
                            render={({ field }) => {
                              const e = getError(day, "end");
                              return (
                                <FormControl
                                  sx={{ width: "100%" }}
                                  error={e !== undefined}
                                >
                                  <TimezonePicker
                                    value={field.value}
                                    label="To"
                                    onChange={(v) => field.onChange(v)}
                                    showTz={false}
                                    disabled={!fieldValue}
                                  />
                                  <FormHelperText>
                                    {e ? e.toString() : "　"}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
