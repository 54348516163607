import { FormControl, FormHelperText, Grid } from "@mui/material";
import ApiContext from "../../context/ApiContext";
import { useContext } from "react";
import { AvatarCard } from "../AvatarCard";
import { titleCase } from "../../utils/utils";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { Controller, useFormContext } from "react-hook-form";
import { default_voice } from "../../utils/consts";

export interface ModelCardProps {}

export function VoiceSection() {
  const {
    data: { voices },
  } = useContext(ApiContext);
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeFormInput>();
  return (
    <Controller
      name="voice"
      control={control}
      render={({ field }) => {
        const e = errors.voice?.message;
        return (
          <FormControl sx={{ width: "100%" }} error={e !== undefined}>
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{padding: '18px 2px'}}
              justifyContent="flex-start"
            >
              {voices &&
                voices.map((v) => {
                  return (
                    <Grid item key={v.name} >
                      <AvatarCard
                        name={titleCase(v.name)}
                        selected={
                          (field.value &&
                            field.value === v.name.toLowerCase()) ||
                          (!field.value &&
                            default_voice() === v.name.toLowerCase())
                        }
                        is_beta={v.is_beta}
                        is_premium={v.is_premium}
                        onClick={() => field.onChange(v.name.toLowerCase())}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </FormControl>
        );
      }}
    />
  );
}
