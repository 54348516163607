import React from 'react';

export const NewtCloudLogoPro = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1290 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1291.000000,166.531342 
	C1290.123901,167.739334 1288.834351,168.302795 1288.433960,169.244415 
	C1282.352661,183.545670 1270.813354,190.765030 1256.073364,190.814621 
	C1153.082886,191.161209 1050.090576,191.122177 947.099426,190.885117 
	C928.269226,190.841766 911.979675,175.055023 911.994324,155.496841 
	C912.020020,121.166321 911.994995,86.835770 912.004211,52.505230 
	C912.008789,35.523575 924.932922,20.539160 941.873352,18.032686 
	C945.932068,17.432171 950.064697,17.045649 954.163574,17.043423 
	C1053.155518,16.989658 1152.147461,17.010998 1251.139404,16.992403 
	C1268.140991,16.989210 1281.468018,23.031338 1288.747559,39.348625 
	C1289.130737,40.207233 1289.890991,40.897545 1290.737793,41.333092 
	C1291.000000,82.687561 1291.000000,124.375122 1291.000000,166.531342 
M1151.145508,127.242264 
	C1157.186890,142.576355 1168.614136,151.304092 1184.706909,153.556381 
	C1191.069824,154.446930 1197.731934,154.541138 1204.105713,153.758591 
	C1222.561646,151.492615 1234.944458,141.437088 1240.299438,123.371284 
	C1243.621216,112.164032 1243.691528,100.755272 1242.059082,89.256935 
	C1239.047363,68.044495 1226.128418,54.616314 1204.927490,51.448116 
	C1199.386597,50.620102 1193.613770,50.726540 1187.998535,51.124641 
	C1168.349487,52.517708 1154.205811,64.209290 1149.436401,83.203705 
	C1145.797119,97.697815 1146.109253,112.211060 1151.145508,127.242264 
M1133.937134,86.956749 
	C1134.891479,69.608582 1129.474487,59.770779 1115.687866,55.371796 
	C1099.147461,50.094219 1082.082642,52.879189 1065.057617,52.169651 
	C1065.057617,86.143700 1065.057617,119.365013 1065.057617,152.690079 
	C1072.159546,152.690079 1078.895264,152.690079 1086.044678,152.690079 
	C1086.044678,139.949524 1086.044678,127.512604 1086.044678,115.179337 
	C1095.464233,113.047272 1096.825928,113.586845 1101.436890,120.999550 
	C1107.417847,130.614716 1113.361450,140.253632 1119.432373,149.811646 
	C1120.237183,151.078781 1121.661255,152.785858 1122.857300,152.834122 
	C1129.927856,153.119415 1137.015747,152.974747 1145.009155,152.974747 
	C1134.942505,137.996368 1125.417725,123.823997 1115.664673,109.312180 
	C1124.942993,104.637306 1131.722290,98.141296 1133.937134,86.956749 
M1002.034302,128.530609 
	C1002.034302,124.917343 1002.034302,121.304070 1002.034302,116.962410 
	C1007.579529,116.962410 1012.550842,117.209740 1017.490295,116.917839 
	C1035.050415,115.880096 1046.546509,106.683907 1049.572876,91.503700 
	C1053.163818,73.492065 1045.732178,59.229969 1029.743896,54.653786 
	C1013.778320,50.084072 997.404968,52.999168 981.049377,52.120895 
	C981.049377,86.143723 981.049377,119.357956 981.049377,152.699768 
	C988.140686,152.699768 994.875183,152.699768 1001.508179,152.699768 
	C1001.728149,152.103439 1001.932922,151.805161 1001.934814,151.505615 
	C1001.981079,144.177307 1002.005737,136.848862 1002.034302,128.530609 
z"
    />
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M0.999999,108.468658 
	C1.664464,107.236946 2.691542,106.573517 2.933095,105.694290 
	C4.255928,100.879272 5.386030,96.011307 6.704279,90.667221 
	C11.564503,92.806595 15.674451,94.615707 19.572134,96.331390 
	C18.318394,103.430229 16.500740,110.258614 16.041504,117.177170 
	C15.433485,126.337173 15.557489,135.582428 15.966654,144.764328 
	C16.520851,157.200806 20.162409,168.995667 26.054489,179.849701 
	C30.937981,188.845749 36.424137,197.613159 42.524998,205.826981 
	C51.888805,218.433838 64.655281,227.348389 78.373856,234.516785 
	C89.808609,240.491806 102.122314,244.376617 115.097191,246.074692 
	C132.024094,248.290009 148.567703,246.924301 164.840942,241.987564 
	C177.800797,238.055984 189.557602,231.695099 200.617203,223.945374 
	C205.281982,220.676666 209.076035,221.336121 212.108948,225.661377 
	C214.278000,228.754669 213.089432,232.378448 208.993454,235.555618 
	C193.023941,247.942825 174.942368,255.471298 155.147614,259.116547 
	C154.591751,259.218903 154.133759,259.852722 153.815063,260.619385 
	C138.979111,261.000000 123.958214,261.000000 108.653564,260.619324 
	C107.700432,259.834747 107.072853,259.184357 106.355629,259.062775 
	C83.667000,255.216949 63.591095,245.482864 46.321194,230.613312 
	C23.324089,210.812592 8.493105,185.910721 2.914080,155.803253 
	C2.785213,155.107819 1.660305,154.596954 0.999999,154.000000 
	C1.000000,138.979111 1.000000,123.958214 0.999999,108.468658 
z"
    />
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1.000000,45.468578 
	C1.667850,44.492821 2.675661,44.126980 2.953877,43.457806 
	C10.068610,26.345259 33.535942,20.447863 48.238976,31.590162 
	C65.203316,44.446133 63.491158,70.727928 46.374294,80.972870 
	C32.553913,89.244766 12.946992,86.522011 3.968513,69.938095 
	C3.280982,68.668175 2.343185,67.533752 1.260858,66.668175 
	C1.000000,59.979053 1.000000,52.958103 1.000000,45.468578 
z"
    />
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M153.531342,1.000000 
	C154.597427,1.660319 155.106476,2.779609 155.805130,2.914110 
	C175.407211,6.687792 193.308075,14.236528 209.120346,26.554394 
	C213.291763,29.803946 214.185028,33.314728 211.531525,36.924042 
	C208.876343,40.535633 205.041733,40.787758 200.595093,38.126057 
	C192.729248,33.417660 185.120987,27.934250 176.680496,24.620220 
	C166.747955,20.720354 156.167862,18.129881 145.647110,16.146000 
	C129.009430,13.008657 112.390152,14.742286 96.466827,20.207298 
	C87.424149,23.310816 78.976364,28.150398 70.270401,32.228931 
	C69.101791,32.776402 67.990410,33.446068 66.639587,34.173882 
	C63.516872,30.599674 60.489716,27.134840 56.636093,22.724043 
	C65.224533,18.386152 73.334328,13.553688 81.999992,10.126933 
	C90.137901,6.908877 98.865883,5.185665 107.321678,2.760934 
	C107.732796,2.643046 108.023186,2.104185 108.184891,1.380677 
	C123.020897,1.000000 138.041794,1.000000 153.531342,1.000000 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M196.760773,189.429230 
	C194.787979,190.570847 192.924652,191.854568 191.032990,191.897797 
	C181.708145,192.110733 172.371582,191.829727 163.047852,192.065933 
	C158.503601,192.181046 156.888824,190.542618 156.932251,185.977692 
	C157.106567,167.653122 157.057938,149.325577 156.951843,130.999939 
	C156.930817,127.364586 156.533493,123.689568 155.889557,120.108383 
	C154.558090,112.703835 150.710068,108.565651 145.139709,108.062584 
	C138.127792,107.429329 131.165405,111.472145 129.219177,118.334961 
	C127.555527,124.201393 126.295654,130.372253 126.143188,136.440765 
	C125.729088,152.924042 125.889267,169.425598 126.066200,185.917297 
	C126.114487,190.417648 124.622581,192.185715 120.012405,192.067505 
	C110.522293,191.824219 101.019524,191.874268 91.526329,192.045410 
	C87.428917,192.119293 85.945541,190.532715 85.956543,186.457092 
	C86.049171,152.137299 86.050568,117.817055 85.956169,83.497284 
	C85.944839,79.377151 87.516922,77.835579 91.572479,77.957985 
	C97.897560,78.148903 104.239136,78.179100 110.561172,77.942276 
	C114.465050,77.796036 116.833603,79.112106 118.022995,82.925201 
	C118.932434,85.840759 120.301468,88.612961 121.797203,92.255272 
	C124.276398,89.618347 125.907181,87.585068 127.833107,85.886406 
	C136.791946,77.984756 147.618225,76.364273 159.048141,76.735321 
	C168.059433,77.027863 176.414505,78.784027 183.609711,84.880875 
	C190.765366,90.944214 194.448318,98.729248 195.834763,107.635231 
	C196.725204,113.355217 196.927689,119.225235 196.949432,125.029839 
	C197.029327,146.352615 196.904785,167.676163 196.760773,189.429230 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M292.000000,126.996071 
	C292.001678,112.338524 292.130157,98.178261 291.931885,84.022575 
	C291.867950,79.457657 293.501587,77.818954 298.045898,77.934067 
	C307.367859,78.170212 316.702301,78.127647 326.026825,77.949127 
	C330.152802,77.870132 332.072876,79.082306 332.057068,83.594078 
	C331.936829,117.907150 331.948639,152.220947 332.044006,186.534164 
	C332.055542,190.683563 330.423035,192.164474 326.397888,192.041397 
	C320.240601,191.853149 314.063599,191.785751 307.913452,192.068756 
	C303.722076,192.261597 301.404938,190.699417 300.232086,186.699814 
	C299.417328,183.921310 298.035370,181.309128 297.026093,178.906540 
	C291.613831,182.697189 286.991425,186.854797 281.627594,189.520370 
	C264.940979,197.812927 243.359818,194.379410 231.636002,182.119507 
	C225.066528,175.249649 222.243393,166.676453 222.124588,157.524414 
	C221.804626,132.876144 222.064255,108.220802 221.956009,83.568878 
	C221.938232,79.519363 223.375793,77.879166 227.498657,77.954414 
	C236.989975,78.127632 246.490829,78.172432 255.979355,77.933365 
	C260.551086,77.818184 262.104980,79.512230 262.066803,84.047401 
	C261.901428,103.701309 261.963928,123.357552 262.040283,143.012573 
	C262.051178,145.815033 262.426117,148.645325 262.923309,151.410461 
	C263.931946,157.020355 267.033447,160.981491 272.888550,161.783127 
	C278.977844,162.616821 284.909698,160.830872 287.245544,155.095230 
	C289.811920,148.793472 290.633209,141.751297 291.926147,134.976624 
	C292.386200,132.565903 291.999939,129.993652 292.000000,126.996071 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M658.000000,146.990021 
	C658.000916,125.827721 657.984436,105.163269 658.013428,84.498886 
	C658.022034,78.341660 658.349487,78.036484 664.571716,78.011642 
	C673.570557,77.975716 682.569763,77.973053 691.568604,78.013443 
	C697.644836,78.040718 697.979919,78.378456 697.988525,84.639214 
	C698.015259,104.137054 697.963684,123.635109 698.041565,143.132675 
	C698.052795,145.935623 698.438354,148.768082 698.950012,151.531494 
	C699.990479,157.151398 703.155029,161.092514 709.018799,161.790543 
	C715.034546,162.506653 720.765625,160.980362 723.371094,155.067047 
	C725.607910,149.990540 727.526245,144.368042 727.787170,138.901138 
	C728.635620,121.119392 728.690796,103.298813 728.985657,85.492088 
	C729.108704,78.059486 729.061340,78.017372 736.577942,78.004135 
	C744.910339,77.989456 753.248291,78.183441 761.573059,77.931755 
	C766.198242,77.791916 768.099854,79.219017 768.077271,84.146164 
	C767.921448,118.141762 767.948120,152.138535 768.053162,186.134521 
	C768.066223,190.369995 766.703918,192.219360 762.292419,192.053589 
	C755.968689,191.815994 749.624390,191.839737 743.298645,192.048019 
	C739.484802,192.173584 737.470764,190.632141 736.447937,187.020493 
	C735.621460,184.102295 734.284363,181.328659 733.013794,178.081940 
	C731.658875,179.039398 731.045044,179.320389 730.637634,179.783142 
	C717.205566,195.036652 700.227295,196.364380 682.166992,191.380112 
	C670.786804,188.239380 663.124390,180.229401 660.341919,168.719910 
	C658.682373,161.855042 658.723450,154.579071 658.000000,146.990021 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M600.000000,145.999985 
	C590.673340,146.003784 581.845947,145.942825 573.020264,146.037659 
	C568.335205,146.087997 567.740417,147.100113 569.358582,151.478821 
	C572.335571,159.534775 579.807495,164.577606 589.152344,164.992249 
	C600.974060,165.516800 612.334412,163.585358 623.298584,159.097076 
	C629.616882,156.510590 629.962952,156.815933 629.992493,163.638031 
	C630.017761,169.466843 629.879761,175.298782 630.031067,181.123688 
	C630.117249,184.442459 628.763184,186.437653 625.704102,187.560303 
	C610.945068,192.976791 595.767029,194.663193 580.113708,193.362015 
	C569.483093,192.478333 559.498596,190.053543 550.391785,184.272873 
	C538.175659,176.518433 531.701050,165.054962 529.231384,151.243912 
	C526.861450,137.990631 527.545349,124.767281 531.038208,111.686569 
	C535.984558,93.162598 554.455505,77.778099 573.645691,77.146080 
	C582.543518,76.853035 591.647522,76.813210 600.367920,78.328545 
	C621.977295,82.083588 636.384766,99.567123 636.958740,121.473656 
	C637.124451,127.797913 636.846130,134.134491 637.041809,140.457031 
	C637.168152,144.538132 635.571472,146.110535 631.475891,146.045685 
	C621.152954,145.882187 610.825562,145.996826 600.000000,145.999985 
M589.464417,121.000000 
	C591.793274,120.991905 594.122620,120.951828 596.450928,120.983192 
	C599.962830,121.030510 601.239502,119.715042 600.199951,116.046082 
	C598.095215,108.617424 592.370789,103.903778 585.089417,104.026741 
	C577.345886,104.157509 571.595642,108.928543 569.684631,116.492523 
	C568.891296,119.632668 569.974121,120.931183 573.007080,120.953217 
	C578.163696,120.990654 583.320618,120.986610 589.464417,121.000000 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M881.232544,151.753326 
	C883.336304,171.820953 874.875183,186.618439 853.726746,191.461273 
	C835.028076,195.743103 816.411194,194.255783 797.993958,189.190262 
	C794.366211,188.192474 792.847046,186.289169 792.941101,182.441742 
	C793.151672,173.823730 793.006897,165.197021 793.006897,155.768402 
	C798.181885,157.387314 802.528198,158.807465 806.910645,160.105637 
	C816.688049,163.001938 826.597534,165.323380 836.860107,163.838272 
	C839.189758,163.501160 841.276550,161.486099 843.475159,160.243362 
	C842.135132,158.155991 841.275024,155.190704 839.364746,154.146271 
	C833.693970,151.045746 827.468689,148.973068 821.723938,145.990601 
	C815.121887,142.563080 808.195557,139.341263 802.477051,134.712051 
	C787.241943,122.379036 786.841003,88.989426 814.322876,80.235863 
	C835.976746,73.338654 857.421997,76.103111 878.096008,85.060089 
	C879.095520,85.493103 880.108704,88.112495 879.708801,89.192940 
	C877.341309,95.587570 874.522583,101.813728 871.929626,108.126923 
	C870.655090,111.229973 868.886047,111.817764 865.675842,110.507637 
	C856.720703,106.852959 847.588196,103.458275 837.693359,104.145012 
	C835.397644,104.304337 833.219055,106.150352 830.986572,107.220284 
	C832.501099,109.216751 833.594910,112.080818 835.604980,113.055481 
	C843.233032,116.754227 851.215759,119.711746 858.957764,123.187325 
	C871.201782,128.683929 880.306458,136.779480 881.232544,151.753326 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M448.893005,88.186302 
	C445.866760,95.785698 442.807587,102.991730 440.128784,110.336510 
	C438.865204,113.801079 437.292755,114.553452 433.815521,113.195259 
	C429.031342,111.326584 424.078918,109.765129 419.074646,108.608078 
	C406.909027,105.795258 398.067780,111.335815 394.929199,123.377945 
	C392.877625,131.249527 392.792694,139.111862 394.782318,147.021683 
	C397.479553,157.744675 405.837799,163.273193 416.834290,161.785141 
	C425.998688,160.544983 434.791687,158.354050 442.685883,153.304520 
	C443.212189,152.967850 443.936829,152.941193 445.221375,152.594116 
	C445.498779,154.133133 445.943115,155.490540 445.955597,156.851929 
	C446.024384,164.345856 445.907501,171.842026 446.024780,179.334747 
	C446.074829,182.533737 444.938812,184.577209 441.991547,186.047440 
	C421.317719,196.360535 400.296021,196.555206 379.352509,187.514725 
	C368.674591,182.905487 361.555573,174.253464 357.629822,163.261169 
	C351.743927,146.780334 351.238068,130.034714 356.117554,113.248344 
	C362.501770,91.285309 378.384155,80.209808 400.048523,77.314789 
	C415.491669,75.251106 430.931183,76.636871 445.528351,83.013573 
	C447.642883,83.937286 450.257019,84.504578 448.893005,88.186302 
z"
    />
    <path
      fill="#333333"
      opacity="1.000000"
      stroke="none"
      d="
M467.000000,50.017242 
	C467.148346,35.360470 465.363220,37.125275 479.533966,37.010040 
	C486.528351,36.953159 493.523834,36.971828 500.518463,37.011856 
	C506.653870,37.046967 506.984436,37.357166 506.987946,43.581810 
	C507.008423,80.221657 507.000000,116.861526 506.999542,153.501389 
	C506.999420,164.493332 506.886871,175.486832 507.045807,186.476440 
	C507.105042,190.572250 505.535065,192.119476 501.457001,192.045135 
	C492.133606,191.875168 482.804840,192.023956 473.478485,191.986908 
	C467.346832,191.962555 467.014465,191.641418 467.011902,185.415192 
	C466.993256,140.448090 467.000793,95.480980 467.000000,50.017242 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1151.035522,126.866898 
	C1146.109253,112.211060 1145.797119,97.697815 1149.436401,83.203705 
	C1154.205811,64.209290 1168.349487,52.517708 1187.998535,51.124641 
	C1193.613770,50.726540 1199.386597,50.620102 1204.927490,51.448116 
	C1226.128418,54.616314 1239.047363,68.044495 1242.059082,89.256935 
	C1243.691528,100.755272 1243.621216,112.164032 1240.299438,123.371284 
	C1234.944458,141.437088 1222.561646,151.492615 1204.105713,153.758591 
	C1197.731934,154.541138 1191.069824,154.446930 1184.706909,153.556381 
	C1168.614136,151.304092 1157.186890,142.576355 1151.035522,126.866898 
M1169.725342,100.549179 
	C1170.816528,107.885849 1170.963135,115.529793 1173.225830,122.485382 
	C1176.418335,132.299454 1184.565186,136.236160 1194.668579,136.337769 
	C1204.663940,136.438278 1212.904785,133.048950 1216.790283,123.371552 
	C1222.332153,109.568680 1222.315918,95.379250 1216.777222,81.572014 
	C1213.075317,72.343628 1205.290039,68.740814 1195.630371,68.667725 
	C1186.072021,68.595406 1177.883789,71.650490 1174.071289,80.845932 
	C1171.651855,86.681366 1171.139526,93.307480 1169.725342,100.549179 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1133.874146,87.388718 
	C1131.722290,98.141296 1124.942993,104.637306 1115.664673,109.312180 
	C1125.417725,123.823997 1134.942505,137.996368 1145.009155,152.974747 
	C1137.015747,152.974747 1129.927856,153.119415 1122.857300,152.834122 
	C1121.661255,152.785858 1120.237183,151.078781 1119.432373,149.811646 
	C1113.361450,140.253632 1107.417847,130.614716 1101.436890,120.999550 
	C1096.825928,113.586845 1095.464233,113.047272 1086.044678,115.179337 
	C1086.044678,127.512604 1086.044678,139.949524 1086.044678,152.690079 
	C1078.895264,152.690079 1072.159546,152.690079 1065.057617,152.690079 
	C1065.057617,119.365013 1065.057617,86.143700 1065.057617,52.169651 
	C1082.082642,52.879189 1099.147461,50.094219 1115.687866,55.371796 
	C1129.474487,59.770779 1134.891479,69.608582 1133.874146,87.388718 
M1086.028320,78.560944 
	C1086.028320,84.647278 1086.028320,90.733612 1086.028320,97.865074 
	C1093.016602,96.829849 1099.199585,96.486145 1105.036621,94.882828 
	C1110.500977,93.381882 1113.007935,88.251518 1112.615845,82.213402 
	C1112.241455,76.445770 1109.646240,72.547684 1103.987427,71.385811 
	C1098.262695,70.210388 1092.320557,70.093376 1086.028320,69.468513 
	C1086.028320,72.968475 1086.028320,75.276077 1086.028320,78.560944 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1002.034302,129.025513 
	C1002.005737,136.848862 1001.981079,144.177307 1001.934814,151.505615 
	C1001.932922,151.805161 1001.728149,152.103439 1001.508179,152.699768 
	C994.875183,152.699768 988.140686,152.699768 981.049377,152.699768 
	C981.049377,119.357956 981.049377,86.143723 981.049377,52.120895 
	C997.404968,52.999168 1013.778320,50.084072 1029.743896,54.653786 
	C1045.732178,59.229969 1053.163818,73.492065 1049.572876,91.503700 
	C1046.546509,106.683907 1035.050415,115.880096 1017.490295,116.917839 
	C1012.550842,117.209740 1007.579529,116.962410 1002.034302,116.962410 
	C1002.034302,121.304070 1002.034302,124.917343 1002.034302,129.025513 
M1004.736816,69.935829 
	C1003.843689,70.619797 1002.186646,71.289093 1002.173218,71.989937 
	C1001.998657,81.083855 1002.057434,90.182259 1002.057434,100.329384 
	C1008.151917,99.583076 1013.665466,99.489639 1018.847900,98.132088 
	C1025.985352,96.262451 1029.316895,90.332298 1028.560059,82.733284 
	C1027.799683,75.098450 1023.917786,71.009361 1016.169678,70.161003 
	C1012.709717,69.782166 1009.185913,69.987061 1004.736816,69.935829 
z"
    />
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1169.748779,100.064102 
	C1171.139526,93.307480 1171.651855,86.681366 1174.071289,80.845932 
	C1177.883789,71.650490 1186.072021,68.595406 1195.630371,68.667725 
	C1205.290039,68.740814 1213.075317,72.343628 1216.777222,81.572014 
	C1222.315918,95.379250 1222.332153,109.568680 1216.790283,123.371552 
	C1212.904785,133.048950 1204.663940,136.438278 1194.668579,136.337769 
	C1184.565186,136.236160 1176.418335,132.299454 1173.225830,122.485382 
	C1170.963135,115.529793 1170.816528,107.885849 1169.748779,100.064102 
z"
    />
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1086.028320,78.072311 
	C1086.028320,75.276077 1086.028320,72.968475 1086.028320,69.468513 
	C1092.320557,70.093376 1098.262695,70.210388 1103.987427,71.385811 
	C1109.646240,72.547684 1112.241455,76.445770 1112.615845,82.213402 
	C1113.007935,88.251518 1110.500977,93.381882 1105.036621,94.882828 
	C1099.199585,96.486145 1093.016602,96.829849 1086.028320,97.865074 
	C1086.028320,90.733612 1086.028320,84.647278 1086.028320,78.072311 
z"
    />
    <path
      fill="#5E6CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1005.213928,69.931442 
	C1009.185913,69.987061 1012.709717,69.782166 1016.169678,70.161003 
	C1023.917786,71.009361 1027.799683,75.098450 1028.560059,82.733284 
	C1029.316895,90.332298 1025.985352,96.262451 1018.847900,98.132088 
	C1013.665466,99.489639 1008.151917,99.583076 1002.057434,100.329384 
	C1002.057434,90.182259 1001.998657,81.083855 1002.173218,71.989937 
	C1002.186646,71.289093 1003.843689,70.619797 1005.213928,69.931442 
z"
    />
  </svg>
);
