import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { useContext, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { default as AuthContext } from "../context/AuthContext";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import {
  showFriendlyErrorFromHTMLCode,
  useMediaMobile,
  useReferer,
} from "../utils/utils";
import RegisterEmailSection from "../components/section/RegisterEmailSection";
import StoreForm from "../components/section/store/StoreForm";
import { theme } from "../theme";
import ApiContext from "../context/ApiContext";
import { AuthUser } from "../interfaces/AuthUser";
import { NewtCloudLogo } from "../img/NewtCloudLogo";

var owasp = require("owasp-password-strength-test");
interface IFormInput {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email field is required.")
      .max(50)
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    password: yup
      .string()
      .required("Password field is required.")
      .max(50)
      .test((value, ctx) => {
        var result = owasp.test(value);
        if (result.errors.length > 0) {
          return ctx.createError({
            message: result.errors[0],
          });
        }
        return true;
      }),
  })
  .required();
interface RegisterViewProps {
  showStore: boolean;
}

export default function RegisterView(props: RegisterViewProps) {
  const navigate = useNavigate();
  const { showStore } = props;
  const isMobile = useMediaMobile();
  const referer = useReferer();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [planIndex, setPlanIndex] = useState<number>(1);
  const methods = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { handleSubmit, reset } = methods;
  const {
    actions: { isLoggedIn, createGuestAccount, updateAccount },
  } = useContext(AuthContext);

  const {
    actions: { getUser },
  } = useContext(ApiContext);

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, password } = data;

    setError("");
    setSuccess("");

    setLoading(true);

    const accountFunction = isLoggedIn() ? updateAccount : createGuestAccount;

    accountFunction(email, password, referer)
      .then(async (token: AuthUser) => {
        await getUser(token); //Load cache to make the store redirect work correctly
        setError("");
        reset();
        navigate(planIndex === 1 ? "/store" : "/");
      })
      .catch((error) => {
        setError(showFriendlyErrorFromHTMLCode(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100dvh"
    >
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <div className="chakra-image css-16duawu">
          <Link href="/">
            <Box sx={{ height: "75px" }}>
              <NewtCloudLogo />
            </Box>
          </Link>
        </div>
        <Paper
          sx={{
            padding: theme.spacing(2),
            overflow: "hidden",
            width: isMobile ? "calc(100vw - 16px)" : undefined,
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="stretch"
              >
                {success && <Alert severity="success">{success} </Alert>}
                {error && <Alert severity="error">{error}</Alert>}

                <Grid
                  container
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid xs={12} lg={4}>
                    <Typography variant="h3">Complete you Account</Typography>
                    <RegisterEmailSection />
                  </Grid>
                  {showStore && (
                    <Grid xs={12} lg={8}>
                      <StoreForm
                        planIndex={planIndex}
                        onIndexChange={(i) => setPlanIndex(i)}
                      />
                    </Grid>
                  )}
                  <Grid xs={0} lg={10}></Grid>
                  <Grid xs={12} lg={2}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      type="submit"
                    >
                      Create Account{" "}
                      {loading ? <LoadingCircle /> : <RightArrow />}
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </FormProvider>
        </Paper>
      </Stack>
    </Box>
  );
}
