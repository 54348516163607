import { Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";

interface TeamMemberHeaderProps {
  index: number;
}

export function TeamMemberHeader(props: TeamMemberHeaderProps) {
  const { index } = props;

  const {
    control,
  } = useFormContext<IHomeFormInput>();

  return (
    <Controller
      control={control}
      name={`agents.${index}.first_name`}
      render={({ field: firstNameField }) => {
        return (
          <Controller
            control={control}
            name={`agents.${index}.last_name`}
            render={({ field: lastNameField }) => {
              let name = "(New Member)";
              if (firstNameField.value && lastNameField.value)
                name = firstNameField.value + " " + lastNameField.value;
              return (
                <Typography variant="h6">
                  {name}
                </Typography>
              );
            }}
          />
        );
      }}
    />
  );
}
