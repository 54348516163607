import { AxiosResponse } from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { default as ApiService } from "../context/ApiServices";
import { TeamMember } from "../interfaces/Agent";
import { AuthUser } from "../interfaces/AuthUser";
import { CardItem } from "../interfaces/CardItem";
import { ICityResult } from "../interfaces/ICityResult";
import { AccessLevel, IGetUserResult } from "../interfaces/IGetUserResult";
import { IVoiceResult } from "../interfaces/IVoiceResult";
import AuthContext from "./AuthContext";
import { default_timezone } from "../utils/consts";

const ApiContext = React.createContext<{
  actions: {
    getUser: (token?: AuthUser) => Promise<IGetUserResult>;
    updateUser: (
      businessName: string,
      voice: string,
      cards: CardItem[],
      agents: TeamMember[],
      welcome_message: string | undefined,
      goodbye_message: string | undefined,
      time_zone: string
    ) => Promise<AxiosResponse<{ result: any }> | undefined>;
    getVoices: () => Promise<IVoiceResult[]>;
    getCities: () => Promise<ICityResult[]>;
    setVoice: (_: string) => void;
    setCompanyName: (_: string) => void;
    setAgents: (_: TeamMember[]) => void;
    setCards: (_: CardItem[]) => void;
    createStoreSession: (_: string) => Promise<string>;
    createStorePortalSession: (_: string) => Promise<string>;
    setLastWarning: (_: string) => void;
  };
  data: {
    email: string;
    voice: string;
    did: string;
    company_name: string;
    accessLevel: AccessLevel | undefined;
    agents: TeamMember[];
    cards: CardItem[];
    welcome_message: string | undefined;
    goodbye_message: string | undefined;
    time_zone: string;
    defaultCards: CardItem[];
    voices: IVoiceResult[];
    cities: ICityResult[];
    lastWarning: string | undefined;
  };
}>({
  actions: {
    getUser: async () => ({
      account: {
        id: -1,
        username: "",
        access_level: AccessLevel.GUEST,
      },
      company: {
        id: -1,
        did: "",
        name: "",
        voice: "",
        users: [],
        cards: [],
      },
    }),
    updateUser: async () => undefined,
    getVoices: async () => [],
    getCities: async () => [],
    setVoice: () => {},
    setCompanyName: () => {},
    setAgents: () => {},
    setCards: () => {},
    createStoreSession: async () => "",
    createStorePortalSession: async () => "",
    setLastWarning: () => {},
  },
  data: {
    voice: "",
    email: "",
    did: "",
    company_name: "",
    accessLevel: AccessLevel.GUEST,
    agents: [],
    cards: [],
    welcome_message: "",
    goodbye_message: "",
    time_zone: default_timezone(),
    defaultCards: [],
    voices: [],
    cities: [],
    lastWarning: undefined,
  },
});

interface ApiContextProviderProps {
  children: React.ReactNode;
}

export function ApiContextProvider(
  props: ApiContextProviderProps
): React.ReactElement {
  const { children } = props;
  const {
    data: { authToken },
    actions: { isLoggedIn, checkToken },
  } = useContext(AuthContext);

  const [voice, setVoice] = useState<string>("");
  const [email, setEmail] = useState("");
  const [did, setDid] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [accessLevel, setAccessLevel] = useState<AccessLevel | undefined>();
  const [agents, setAgents] = useState<TeamMember[]>([]);
  const [cards, setCards] = useState<CardItem[]>([]);
  const [welcome_message, setWelcomeMessage] = useState<string | undefined>();
  const [goodbye_message, setGoodbyeMessage] = useState<string | undefined>();
  const [time_zone, setTimeZone] = useState<string>(default_timezone());

  const [voices, setVoices] = useState<IVoiceResult[]>([]);
  const [cities, setCities] = useState<ICityResult[]>([]);

  const [defaultCards, setDefaultCards] = useState<CardItem[]>([]);

  const WARNING_KEY = "warning";
  const [warning, setWarning] = useState<string | undefined>(
    localStorage.getItem(WARNING_KEY) || undefined
  );
  const setLastWarning = (warning: string | undefined) => {
    setWarning(warning);
  };

  const setUserResult = (data: IGetUserResult) => {
    setVoice(data.company.voice);
    setEmail(data.account.username);
    setDid(data.company.did);
    setCompanyName(data.company.name);
    setAccessLevel(data.account.access_level);
    setAgents(
      data.company.users.map((agent) => {
        return { ...agent, phone: agent.phone.slice(2) };
      })
    );
    setCards(
      data.company.cards
        .map((card) => ({ ...card }))
        .sort((a, b) => a.sort_order - b.sort_order)
    );
    setWelcomeMessage(data.company.welcome_message);
    setGoodbyeMessage(data.company.goodbye_message);
    setTimeZone(data.company.time_zone || default_timezone());
  };

  const getUser = useCallback(
    (t?: AuthUser) => {
      return new Promise<IGetUserResult>(async (resolve, reject) => {
        const token = t ? t : authToken;
        if (!isLoggedIn(token)) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = await ApiService.api.get(`/user`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token?.access_token}`,
            },
          });

          setUserResult(response.data);
          resolve(response.data);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const updateUser = useCallback(
    (
      businessName: string,
      voice: string,
      cards: CardItem[],
      agents: TeamMember[],
      welcome_message: string | undefined,
      goodbye_message: string | undefined,
      time_zone: string
    ) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!isLoggedIn()) {
          reject("You are not logged in. Please log in and try again");
        }
        try {
          const response = ApiService.api.put(
            `user`,
            {
              name: businessName,
              voice: voice,
              cards: cards,
              users: agents,
              welcome_message: welcome_message,
              goodbye_message: goodbye_message,
              time_zone: time_zone,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken?.access_token}`,
              },
            }
          );
          resolve(response);
        } catch (e: any) {
          reject(e);
        }
      });
    },
    [authToken, isLoggedIn]
  );

  const getVoices = useCallback(() => {
    return new Promise<IVoiceResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`voices`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const voices: IVoiceResult[] = response.data;
        if (voices) {
          const sorted = voices.sort((a, b) => {
            if (a.is_premium === b.is_premium) return a.name > b.name ? 1 : -1;
            return a.is_premium && !b.is_premium ? 1 : -1;
          });
          setVoices(sorted);
          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getCities = useCallback((prefix?: string) => {
    return new Promise<ICityResult[]>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`cities`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        const cities: ICityResult[] = response.data;
        if (cities) {
          const sorted = cities.sort((a, b) => {
            if (a.region === b.region) return a.city > b.city ? 1 : -1;
            return a.region > b.region ? 1 : -1;
          });

          resolve(sorted);
        } else {
          resolve([]);
        }
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const getDefaultCards = useCallback(() => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await ApiService.api.get(`default_context`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setDefaultCards(response.data.cards);
        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStoreSession = useCallback((return_url?: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = checkToken();
        const response = await ApiService.api.post(
          `stripe/create`,
          { return_url },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  const createStorePortalSession = useCallback((return_url?: string) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const token = checkToken();
        const response = await ApiService.api.post(
          `stripe/portal/create`,
          { return_url },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token?.access_token}`,
            },
          }
        );

        resolve(response.data);
      } catch (e: any) {
        reject(e);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      const v = await getVoices();

      setVoices(v);
    }
    fetchData();
  }, [getVoices]);

  useEffect(() => {
    async function fetchData() {
      setCities(await getCities(""));
    }
    fetchData();
  }, [getCities]);

  useEffect(() => {
    if (isLoggedIn()) getUser();
  }, [authToken, getUser, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn()) getDefaultCards();
  }, [authToken, getDefaultCards, isLoggedIn]);

  return (
    <ApiContext.Provider
      value={{
        actions: {
          getUser,
          updateUser,
          getVoices,
          getCities,
          setVoice,
          setCompanyName,
          setAgents,
          setCards,
          createStoreSession,
          createStorePortalSession,
          setLastWarning,
        },
        data: {
          accessLevel,
          email,
          voice,
          did,
          company_name,
          agents,
          cards,
          welcome_message,
          goodbye_message,
          time_zone,
          defaultCards,
          voices,
          cities,
          lastWarning: warning,
        },
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
export default ApiContext;
